import { Link } from "gatsby";
import React, { Component } from "react";

class Others extends Component {
  render() {
    const { backgroundColor, fillColor, previous, next} = this.props;
    return (
      <section
        id="projectitem"
        className="projectitem__others"
        style={{ backgroundColor: backgroundColor }}
      >
        <div className="container">
          <div className="projectitem__others__content">
            <Link to={previous} className="previous">
              <svg
                width="48"
                height="24"
                viewBox="0 0 48 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 11.5L47 11.5M47 11.5L35.5 23M47 11.5L36.5 0.999997"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ stroke: `${fillColor}` }}
                />
              </svg>

              <h2 style={{ color: `${fillColor}` }}>Previous</h2>
            </Link>
            <Link to={next} className="next">
              <h2 style={{ color: `${fillColor}` }}>Next</h2>
              <svg
                width="48"
                height="24"
                viewBox="0 0 48 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 11.5L47 11.5M47 11.5L35.5 23M47 11.5L36.5 0.999997"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  style={{ stroke: `${fillColor}` }}
                />
              </svg>
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

export default Others;
