import React, { Component } from "react";

export class Task extends Component {
  render() {
    const {
      collaborators,
      background,
      contributions,
      problemDescription,
      problemList
    } = this.props;

    return (
      <section id="projectitem">
        <div className="container">
          <div className="projectitem__task">
            <div>
              <div className="projectitem__background">
                <h2>Background</h2>
                <p>{background}</p>
              </div>
              <div className="projectitem__problem">
                <h2>Problem</h2>
                <p>{problemDescription}</p>
              </div>
            </div>
            <div>
              <div className="projectitem__contributions">
                <h2>Contributions</h2>
                <ul>
                  {contributions.map((item) => {
                    return <li key={item}>{item}</li>;
                  })}
                </ul>
              </div>
              <div className="projectitem__collaborators">
                <h2>Collaborators</h2>
                <ul>
                  {collaborators.map((item) => {
                    return <li key={item}>{item}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Task;
