import React, { Component } from "react";

export class Research extends Component {
  render() {
    const { children } = this.props;
    return (
      <section id="projectitem">
        <div className="container">
          <div className="projectitem__research">
            <h2>Research</h2>
            <div className="projectitem__research__content">
                {children}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Research;
