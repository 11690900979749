import React, { Component } from "react";



export class Banner extends Component {
  render() {
    const { image, alt, backgroundColor } = this.props;

    return (
      <div
        className="projectitem__banner"
        style={{ backgroundColor: backgroundColor }}
      >
        <img src={image} alt={alt} />
      </div>
    );
  }
}

export default Banner;
