import React, { Component } from "react";
import { graphql } from "gatsby";

import "../../styles/index.scss";

import Layout from "../../components/layout";
import Goal from "../../components/projects/goal";
import Header from "../../components/projects/header";
import Task from "../../components/projects/task";
import Role from "../../components/projects/role";
import Banner from "../../components/projects/banner";
import Research from "../../components/projects/research";
import Visual from "../../components/projects/visual";
import Learning from "../../components/projects/learning";
import Others from "../../components/projects/others";

import header from "../../images/bumpa/header.png";
import bannerImg from "../../images/bumpa/banner.png";
import brand from "../../images/bumpa/brand.png";
import oldOrder from "../../images/bumpa/order-old.png";
import newOrder from "../../images/bumpa/order-new.png";
import flutterwave from "../../images/bumpa/flutterwave.png";
import companalysis from "../../images/bumpa/competitive-analysis.png";
import paystack from "../../images/bumpa/paystack.png";
import selar from "../../images/bumpa/selar.png";
import onboarding from "../../images/bumpa/onboarding.png";
import easeone from "../../images/bumpa/ease-one.png";
import easetwo from "../../images/bumpa/easetwo.png";
import analytics from "../../images/bumpa/analytics.png";

import onboardingVideo from "../../videos/bumpa-onboarding.mp4";
import beforeOnboardingVideo from "../../videos/bumpa-before-onboarding.mp4";
/* import beforeAddProductVideo from "../../videos/bumpa-addproduct.mp4"; */
import afterAddProductVideo from "../../videos/bumpa-addproduct-new2.mp4";
import addProductVideo from "../../videos/bumpa-addproduct-new.mp4";
import analyticsVideo from "../../videos/bumpa-analytics.mp4";

class Bumpa extends Component {
  render() {
    /*     const { data } = this.props; */

    const info = {
      title: "Bumpa",
      description:
        "Helping small businesses manage their businesses effectively.",
      date: "August 2021 - October 2021",
      url: "https://bumpa.app",
      linkName: "View app",
      image: `${header}`,
      backgroundColor: "#ECFFF3",
      titleColor: "#009444",
    };

    const capacity = {
      collaborators: [
        "Kelvin Umechukwu - Product Owner",
        "Adetunji Opaleye - Engineering Lead",
        "Stephen Ojo- Mobile Engineer",
      ],
      contributions: [
        "User Research",
        "Information Architecture",
        "Interface Design",
        "Product Strategy",
        "Brand Design",
      ],
      background:
        "Bumpa is an inventory management system that helps small businesses manage their inventory, drive sales from multiple channels (offline and online), provide enough data to grow their business, and engage with their customers.",
      problemDescription:
        "Bumpa was originally known salescabal built in 2017 with 1,500 signups and 1,000 monthly orders. Salescabal’s goal was to provide small and medium businesses with an online store. Salescabal struggled to scale due to poor market fit, usability issues with the platform and a fight for market share with similar products.",
    };

    const goalOutcome = {
      goal: "Improve the customer experience of the mobile app, thereby helping the business scale and grow their user base. The measures of success were increase in number of signups, increase in monthly orders recorded on the app, increase in gross merchant value.",
      outcome:
        "<b>Over 70k signups</b> after the redesign process completed, compared to the initial 1k subscribers, over <b>$1m GMV </b> compared to the initial $8k GMV, <b> 14k+ orders monthly orders recorded</b> compared to the initial 283 monthly orders , <b>$800k pre-seed raised</b>",
    };

    const role = {
      description:
        "I collaborated with the Product Owner to define the product strategy, conduct user research and usability testing. I also led the visual design efforts, from branding to UI Design and collaborated with the developers to ensure it was adequately implemented.",
    };

    const banner = {
      alt: "Banner Image",
    };

    const companies = [
      {
        logo: `${flutterwave}`,
        name: "Flutterwave",
        strength: [
          "Easy integration with existing platforms through its API",
          "Simple and clean user interface",
          "Focused on online orders",
        ],
        weaknesses: [
          "No means to record offline sales or sales from order sources",
          "No detailed analytics",
          "Users cannot add own domain",
          "No mobile app",
        ],
      },
      {
        logo: `${paystack}`,
        name: "Paystack",
        strength: [
          "Easy integration with existing platforms through its API",
          "Simple and clean user interface",
          "Focused on online orders",
        ],
        weaknesses: [
          "No means to record offline sales or sales from order sources",
          "No detailed analytics",
          "No ability to add multiple variations",
          "Users cannot add own domain",
          "No mobile app",
        ],
      },
      {
        logo: `${selar}`,
        name: "Selar",
        strength: [
          "Clear offering",
          "Multiple currencies available",
          "Connection to various payment methods",
          "Users can add thier own domain",
        ],
        weaknesses: ["Solely focused on digital products", "No mobile app"],
      },
    ];

    return (
      <Layout newStyle="dark" pageTitle="projectitem bumpa">
        <Header
          title={info.title}
          description={info.description}
          date={info.date}
          link={info.link}
          image={header}
          backgroundColor={info.backgroundColor}
          linkName={info.linkName}
          url={info.url}
          titleColor={info.titleColor}
        />
        <Task
          collaborators={capacity.collaborators}
          background={capacity.background}
          contributions={capacity.contributions}
          problemDescription={capacity.problemDescription}
        />
        <Goal
          goal={goalOutcome.goal}
          outcome={goalOutcome.outcome}
          backgroundColor={info.backgroundColor}
          titleColor={info.titleColor}
        />
        <Role role={role.description} />
        <Banner
          image={bannerImg}
          alt={banner.alt}
          backgroundColor={info.backgroundColor}
        />
        <Research>
          <div className="projectitem__research__compare">
            <p>
              To properly understand the problem to be solved, I conducted
              research using the following methods: Heuristics evaluation of the
              existing application, competitive analysis of existing
              competitors, and remote user interviews. We evaluated existing
              products and looked into other markets essential towards learning
              from them. Remote user interviews were conducted with 10
              participants over zoom and whatsapp. Participants were recruited
              from existing Bumpa customers and churned customers. Here are some
              of the questions asked:
            </p>
            <ul>
              <li>What type of business do you run?</li>
              <li>Do you have a physical store?</li>
              <li>
                Do you sell online on any social media platform? Which ones do
                you sell on?
              </li>
              <li> What are the channels you recieve the most sales from? </li>
              <li>
                What’s your most used feature on the app? and what’s your least
                used feature on the app?
              </li>
              <li>
                What features require improvements on the current Salescabal
                app?
              </li>
              <li>
                What are the features that are currently not existing on the app
                that can help you and your business?
              </li>
            </ul>

            <div className="bk bumpa">
              <img
                src={companalysis}
                alt="Bumpa Competitive Analysis"
                className="companalysis"
              />
            </div>

            <h3>KEY FINDINGS</h3>
            <ul>
              <li>Only about 10% of the monthly orders were online orders. Users had more offline sales and sales via Instagram, Whatsapp and Facebook.</li>
              <li>The process for adding products on the app was too long, and does not replicate their real world scenarios.</li>
              <li>
              New Users were confused on what to do after signing up, and this led to them not using the app as they should.
              </li>
              <li> What are the channels you recieve the most sales from? </li>
              <li>
              Users required more comprehensive analytics on the state of their business.
              </li>
            </ul>

          </div>
        </Research>

        <Visual title="Community-Driven Iterative Design Process">
          <p>
            We wanted to move fast and break things, so we can learn more from
            our customers. We created a community of beta-testers of ideas we
            had in ways the product could evolve. We wanted to leave an open
            line of communication for our customers to reach us so we can learn
            what they require the most for the product, and how the product can
            evolve. I constantly tested the ideas of the product either by
            conducting user interviews with them, demoing what we were currently
            working on, and received valuable feedback. Some of the important
            sections improved on the app are:
          </p>

          <h3>Improved onboarding experience</h3>
          <p>
            The initial onboarding was a simple sign-up process, and users were
            left confused about what to do after setting up their accounts. Our
            first iteration had a to-do list on the homepage. Our initial
            assumption that users can easily find the to-do list and know what
            to do proved untrue. Users wanted everything spelled out step by
            step.
          </p>

          <div className="bk bumpa brand">
            <img src={onboarding} alt="Bumpa onboarding" />
          </div>

          <h3>Ease of adding a product</h3>
          <p>
            The initial flow for adding a product was a long-form which users
            found overwhelming to fill when they had more products. They also
            found that some details weren’t particularly relevant to their type
            of business, e.g., adding product options, which dissuaded them from
            adding the products. The goal for this section was to reduce the
            time needed to add a new product, providing flexibility for adding
            products. First, we introduced a quick method that allows users to
            add the product when they are recording an order. This way, they
            don’t need to add the products before using the app. Also, we
            introduced a minified version of the add product page, and users can
            add more details if they need to add more information about the
            products.
          </p>
          <div className="bk bumpa">
            <img src={easeone} alt="Bumpa onboarding" />
          </div>
          <div className="bk bumpa">
            <img src={easetwo} alt="Bumpa onboarding" />
          </div>

          <h3>Simplifying order status</h3>
          <p>
            We modeled our initial order status after existing products in other
            markets, showing paid, unpaid, fulfilled, unfulfilled. Users found
            it hard to group each of the orders. Users wanted a more
            straightforward way to know the orders that required more actions.
            So while we kept the vocabulary, we changed the filters to enable
            users to get a quick result on orders they need to complete. We
            introduced “All,” “Pending - unfulfilled or unpaid orders,”
            “completed - paid & delivered” orders.
          </p>

          <div className="bk bk-grid bumpa">
            <div class="image-column">
              <h6>Before</h6>
              <img src={oldOrder} alt="Bumpa User flow" />
            </div>
            <div className="image-column">
              <h6>After</h6>
              <img src={newOrder} alt="Bumpa User flow" />
            </div>
          </div>

          <h3>Introducing a comprehensive data analytics</h3>
          <p>
            We asked users what important details are needed to help them make
            better informed business decisions and introduced an analytics page
            to application.
          </p>

          <div className="bk bumpa">
            <img src={analytics} alt="Bumpa analytics" />
          </div>
        </Visual>
        <Learning name="Key Takeaways">
          <p>
            It was an amazing experience working on the product with the
            founders, seeing the traction they are on at the moment makes me
            happy. As with every experience, here are my major takewaways: {" "}
          </p>
          <h3>Prioritize Experience Over Features</h3>
          <p>
            A startup means you have to make hard calls. You have to decide what
            to cut out to ship on time. I learned this balance— while still
            sticking to quality. It worked well because people responded to our
            difference in quality.
          </p>
          <h3>Don't be too precious over an idea.</h3>
          <p>
            The product has gone through many versions, some of them made
            public. It’s important not to hesitate to kill your babies. I debate
            with the product owner and mobile engineer for the best ideas. When
            we see a good idea, we take it; it's best for the product.
          </p>
          <h3>You can do more with less</h3>
          <p>
          A large team might not be necessarily needed to deliver the best products. I learnt innovative ways to test ideas fast and quickly.
          </p>
        </Learning>
        <Others
          backgroundColor={info.backgroundColor}
          fillColor={info.titleColor}
          previous="/projects/urbansitter"
          next="/projects/indicina"
        />
      </Layout>
    );
  }
}

export default Bumpa;

export const query = graphql`
  query {
    banner: file(relativePath: { eq: "bumpa/banner.png" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          transformOptions: { fit: COVER }
        )
      }
    }
    header: file(relativePath: { eq: "bumpa/header.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500, placeholder: BLURRED)
      }
    }
  }
`;
