import React, { Component } from "react";

export class Visual extends Component {
  render() {
    const { children, title } = this.props;
    return (
      <section id="projectitem">
        <div className="container">
          <div className="projectitem__visual">
            <h2>{title}</h2>
            <div className="projectitem__visual__content">{children}</div>
          </div>
        </div>
      </section>
    );
  }
}

export default Visual;
