import React, { Component } from "react";

export class Header extends Component {
  render() {
    const { title, description, date, linkName, url, image, backgroundColor, titleColor} = this.props;

    return (
      <header className="projectitem__header" style={{ backgroundColor: backgroundColor }}>
        <div className="projectitem__content">
            <div className="projectitem__intro">
              <h6 className="projectitem__intro__title" style={{ color: titleColor }}>{title}</h6>
              <h1 className="projectitem__intro__description">
              {description}
              </h1>
              <p className="projectitem__intro__date">{date}</p>
              <a className="projectitem__intro__url" href={url} target="_blank" rel="noreferrer" style={{ color: `${titleColor}`, borderBottom: "4px solid" + `${titleColor}`}}>{linkName}</a>
            </div>
            <div className="projectitem__content__image">
                <img src={image} alt="Project Header Image" />
            </div>
        </div>
      </header>
    );
  }
}

export default Header;
