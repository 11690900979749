import React, { Component } from "react";

export class Goal extends Component {
  render() {
    const { goal, outcome, backgroundColor, titleColor } = this.props;
    return (
      <section id="projectitem">
        <div className="container">
          <div
            className="projectitem__goaloutcome"
            style={{ backgroundColor: backgroundColor }}
          >
            <div className="projectitem__goal">
              <h2 style={{ color: titleColor }}>Goal</h2>
              <p>{goal}</p>
            </div>
            <div className="projectitem__outcome">
              <h2 style={{ color: titleColor }}>Outcome</h2>
              <p dangerouslySetInnerHTML={{__html: outcome}} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Goal;
