import React, { Component } from "react";

export class Role extends Component {
  render() {
    const {role} = this.props;
    return (
      <section id="projectitem">
        <div className="container">
          <div className="projectitem__role">
            <h2>My role</h2>
            <p>{role}</p>
          </div>
        </div>
      </section>
    );
  }
}

export default Role;
