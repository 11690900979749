import React, { Component } from "react";

export class Learning extends Component {
  render() {
    const { name, children } = this.props;
    return (
      <section id="projectitem">
        <div className="container">
          <div className="projectitem__learning">
            <h2>{name}</h2>
            <div className="projectitem__learning__content">{children}</div>
          </div>
        </div>
      </section>
    );
  }
}

export default Learning;
